'use strict'
var ours = {}
var con = global.console || ours
var prop, method
var dummy = function () {}
var properties = ['memory']
var methods = (
  'assert,clear,count,debug,dir,dirxml,error,exception,group,' +
  'groupCollapsed,groupEnd,info,log,markTimeline,profile,profiles,profileEnd,' +
  'show,table,time,timeEnd,timeline,timelineEnd,timeStamp,trace,warn'
).split(',')
while ((prop = properties.pop())) {
  if (con[prop]) {
    ours[prop] = con[prop]
  } else {
    ours[prop] = {}
  }
}
while ((method = methods.pop())) {
  if (typeof con[method] === 'function') {
    ours[method] = con[method]
  } else {
    ours[method] = dummy
  }
}

export const log = ours.log.bind(con)
export const error = ours.error.bind(con)
export const info = ours.info.bind(con)
export const warn = ours.warn.bind(con)
export const debug = ours.debug.bind(con)
export const assert = ours.assert.bind(con)
